import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    Skeleton,
} from '@chakra-ui/react';

import { AiFillPrinter } from "react-icons/ai";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import { useLocation } from 'react-router-dom';
import DataTable from '../components/UserDataTable';
export default function Bookings() {


    const user = useRecoilValue(userAtom);
    const mql = window.matchMedia('(max-width: 480px)');

    let mobileView = mql.matches;
    const [tableData1, setData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [dataLoaded2, setDataLoaded2] = useState(false);
    const [campaignName, setCampaignName] = useState();
    const [stats, setStats] = useState({});
  
  
    const getData = () => {
        const req = axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com' + "/api/users/all/locticians").then(res => {
          let users = res.data;
    
          users = users.map(user => {
            // check each field if it exists and convert true --> Y, false --> N
            if ("isLoctician" in user) {
                user.isLoctician = user.isLoctician ? "Y" : "N";
            }

            return user;
          });
    
          setData(users);
          setDataLoaded(true);
        }).catch(err => {
          console.log(err);
        });
    }
    

    useEffect(() =>{
        getData();
      }, []) ;
    



    const columnsData1 = [
        {
          Header: "ID",
          accessor: "_id"
        },
        {
          Header: "Email",
          accessor: "email"
        },
        {
            Header: "Phone",
            accessor: "phone"
        },
        {
          Header: "Name",
          accessor: "fullName"
        },
  
      
      ];
      


  return (
    <Flex w="100%" bg='#000'>
      <Sidebar />
      <Box p={4} w='full' pb={0}>

        <Box w='100%'>
            <Text textAlign={'center'} color='#fff' fontSize='2rem' fontWeight='800'>Service Providers</Text>
            <Text textAlign={'center'} color='gray.200' fontSize='1rem' fontWeight='500'>
                Manage locticians accounts and profiles, servicees, prices, schedules and reviews
            </Text>

                <Box w='100%' >
                    <Flex>
                        <Flex direction='column' flex={1}>

                        <Skeleton isLoaded={dataLoaded}>
                            <Box>
                              <DataTable
                                  tableData={tableData1}
                                  columnsData={columnsData1}
                                  tableType='CantactList'
                                  hideButtons={true}
                                  userEmail={user.email}
                              />
                            </Box>
                        </Skeleton>
                        </Flex>

                    </Flex>
                </Box>
        </Box>
      </Box>
    </Flex>
  )
}