import { 
    Box, 
    Flex,
    Text,
    InputGroup,
    InputRightAddon,
    Button,
    Input,
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { useRef, useState } from 'react';
import axios from 'axios';
import { FaRegEye } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { firebaseApp } from '../firebase/firebaseConfig'


const LoginComponent = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  
  const history = useNavigate();

  const [_, setUser] = useRecoilState(userAtom);

  const [showPassword, setShowPassword] = useState(false);
  const [isDataLoading, setDataLoading] = useState(false);
  const [err201, seterr201] = useState(false);
  const [errNetwork, seterrNetwork] = useState(false);

  const handleClick = () => {
    setDataLoading(true);
    
    const auth = getAuth(firebaseApp);
    signInWithEmailAndPassword(auth, emailRef.current.value.trim(), passwordRef.current.value)
      .then((userCredential) => {
        var user = userCredential.user;
        console.log(user);
        // Check if email matches "locseatapp@gmail.com"
        if (user.email === 'locseatapp@gmail.com') {
          // Set the user state to be the authenticated user's data
          axios.post('https://locseat-backend-e47bf5c07959.herokuapp.com' + "/api/login/admin", {
            email: emailRef.current.value.trim(),
          }).then(res => {
            seterr201(false);
            setUser(res.data.user)
            history('/home')
            console.log('user logged');
            setDataLoading(false);
          }).catch(err => {
            seterr201(true);
            console.log(err)
            alert("Invalid credentials")
            setDataLoading(false);
          });
        } else {
          alert("You are not authorized to log in.");
          setDataLoading(false);
        }
      })
      .catch((error) => {
        console.log(error)
        
        // Handle the error here - for example, you could set an error state
        seterr201(true);
        setDataLoading(false);
      });
  };
  





  return (
    <Box>
        <Box w={["95%", "30vw"]} margin='0 auto' mt='15vh'>
          <Flex direction='column' color='#000'>
              <Text mb={4} color="#000" textAlign='center' fontSize="2rem" fontWeight="900">Locseat Admin</Text>
              <Text textAlign='center' fontWeight='600' fontSize='1.5rem'>Log in</Text>
              {err201 && 
                <Box p={4} textAlign='center'>
                  <Text color='red.300'>Something went wrong. Check your inputs and try again.</Text>  
                </Box>
              }
              
              <Box mb={4}>
                <Flex justifyContent='space-between'>
                  <Text>Email</Text>
                </Flex>
                <Input ref={emailRef}/>
              </Box>

              <Box mb={4}>
                <Flex justifyContent='space-between'>
                  <Text>Password</Text>
                  {/* <Text>Forgot username?</Text> */}
                </Flex>
                <InputGroup>
                  <Input 
                    ref={passwordRef}                   
                    type={showPassword?'':'password'} 
                  />
                  <InputRightAddon
                    _hover={{ cursor: 'pointer'}}  
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <FaRegEye/>
                  </InputRightAddon>              
                </InputGroup>
              </Box>
              <Box>
                <Button 
                  isLoading={isDataLoading}
                  _hover={{ bg: '#000' }} 
                  bg="#000" 
                  w='100%' 
                  color='white' 
                  fontWeight='500' 
                  fontSize='1.1rem'
                    onClick={() =>handleClick()}
                >
                  LOG IN
                </Button>
              </Box>
          </Flex>
        </Box>
    </Box>
  )
}

export default LoginComponent