import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    Skeleton,
} from '@chakra-ui/react';

import { AiFillPrinter } from "react-icons/ai";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import { useLocation } from 'react-router-dom';
import DataTable from '../components/BookingDataTable';
export default function Bookings() {


    const user = useRecoilValue(userAtom);
    const mql = window.matchMedia('(max-widt h: 480px)');

    let mobileView = mql.matches;
    const [tableData1, setData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [dataLoaded2, setDataLoaded2] = useState(false);
    const [campaignName, setCampaignName] = useState();
    const [stats, setStats] = useState({});
  
  
    const getData = () => {
        const req = axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com' + "/api/appointmets/all").then(res => {
          let appointments = res.data;
    
          appointments = appointments.map(appointment => {
            // check each field if it exists and convert true --> Y, false --> N
            if ("confirmed" in appointment) {
                appointment.confirmed = appointment.confirmed ? "Y" : "N";
            }
            if ("canceled" in appointment) {
                appointment.canceled = appointment.canceled ? "Y" : "N";
            }
            if ("reviewed" in appointment) {
                appointment.reviewed = appointment.reviewed ? "Y" : "N";
            }
            return appointment;
          });
    
          setData(appointments);
          setDataLoaded(true);
        }).catch(err => {
          console.log(err);
        });
    }
    

    useEffect(() =>{
        getData();
      }, []) ;
    



    const columnsData1 = [
        {
          Header: "ID",
          accessor: "_id"
        },
        {
          Header: "Date Created",
          accessor: "dateCreated"
        },
        {
          Header: "Appointment Date",
          accessor: "appointmentDate"
        },
        {
            Header: "Client",
            accessor: "clientID"
        },
        {
            Header: "Loctician",
            accessor: "locticianID"
        },
        {
          Header: "Conmfirmed",
          accessor: "confirmed"
        },
        {
            Header: "Canceled",
            accessor: "canceled"
        },
        // {
        //     Header: "Reviewed",
        //     accessor: "reviewed"
        // },
      
      ];
      


  return (
    <Flex w="100%" bg='#000'>
      <Sidebar />
      <Box p={4} w='full' pb={0}>

        <Box w='100%'>
            <Text textAlign={'center'} color='#fff' fontSize='2rem' fontWeight='800'>Booking Management</Text>
            <Text textAlign={'center'} color='gray.200' fontSize='1rem' fontWeight='500'>
                Oversee all appointment bookings, pending, confirmed, completed & cancelled.
            </Text>

                <Box w='100%' >
                    <Flex>
                        <Flex direction='column' flex={1}>

                        <Skeleton isLoaded={dataLoaded}>
                            <Box bg='#000'>
                              <DataTable
                                  tableData={tableData1}
                                  columnsData={columnsData1}
                                  tableType='CantactList'
                                  hideButtons={true}
                                  userEmail={user.email}
                              />
                            </Box>
                        </Skeleton>
                        </Flex>

                    </Flex>
                </Box>
        </Box>
      </Box>
    </Flex>
  )
}