import { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    Skeleton,
    Badge,
    Grid,
    VStack,
} from '@chakra-ui/react';
import { FaStripe, FaUsers, FaGoogle } from "react-icons/fa";
import { AiFillPrinter } from "react-icons/ai";
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import DataTable from '../components/ActiveConvosDataTable';
import { useLocation } from 'react-router-dom';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';


export default function Support() {

    const [tableData1, setData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [activeTab, setActiveTab] = useState('OPEN'); // new state to determine active tab
    const [openTicketCount, setOpenTicketCount] = useState(0); // new state for open ticket count

    

    const user = useRecoilValue(userAtom);
    const mql = window.matchMedia('(max-width: 480px)');
    

    let mobileView = mql.matches;

    useEffect(() => {
        const endpoint = activeTab === 'OPEN' 
            ? '/api/list-conversations'
            : '/api/list-closed-conversations'; // decide the endpoint based on active tab

        axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com' + endpoint)
        .then(response => {
          const data = response.data;
          let convos = data._embedded.conversations || [];
            
          const processedData = convos.map(convo => {
              return {
                  ...convo, 
                  customer: convo.createdBy.email,
                  conversation: convo.preview.slice(0, 50)+ '...',
                  number: convo.number,
                  waitingSince: convo.customerWaitingSince.friendly
              };
          });

          setData(processedData);
          if (activeTab === 'OPEN') {
            setOpenTicketCount(convos.length); // update open ticket count
        }
          setDataLoaded(true);
      })
      .catch(error => console.error('Error:', error));
    }, [activeTab]); 



    const columnsData1 = [
   
        {
          Header: "Customer",
          accessor: "customer"
        },
        {
          Header: "Conversation",
          accessor: "conversation"
        },
        {
            Header: "Number",
            accessor: "number"
          },
        {
            Header: activeTab == "OPEN" ? "Waiting Since" : "Closed",
            accessor: activeTab == "OPEN" ? "waitingSince" : 'closedAt'
        },

      
      ];


    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setDataLoaded(false); // set dataLoaded to false to show a loading skeleton when data is being fetched
    };
    const TopBar = () => {

        return (
            <Flex justifyContent={'space-evenly'} w='100%' p={2} bg='#212121' borderRadius={12}>
                <Flex
                    onClick={() => handleTabClick('OPEN')} 
                    borderBottom={activeTab === 'OPEN' ? '2px solid white' : 'none'} // visual indicator for active tab
                >
                    <Text color={'white'} fontWeight={'extrabold'}>OPEN</Text>
                    <Badge mx={2} px="2" py={0} colorScheme='blackAlpha'>
                        <Text color={'white'} fontWeight={'extrabold'}>{openTicketCount} </Text>

                            
                    </Badge>
                </Flex>
                
                <Box 
                    onClick={() => handleTabClick('CLOSED')}
                    borderBottom={activeTab === 'CLOSED' ? '2px solid white' : 'none'}
                >
                    <Text color={'white'} fontWeight={'extrabold'}>CLOSED</Text>
                </Box>
            </Flex>
        )
    }

    return (
      <Flex w="100%" bg='#000'>
          <Sidebar />
          <Box p={4} w='full' pb={0}>
              <Box w='100%'>
              <Text textAlign={'center'} color='#fff' fontSize='2rem' fontWeight='800'>Customer Support</Text>
            <Text textAlign={'center'} color='gray.200' fontSize='1rem' fontWeight='500'>
                Oversee all customer support tickets
            </Text>
                <Flex w='80%' m='0 auto' mb={4}>
                    <TopBar/>
                </Flex>

                <Flex>
                        <Flex direction='column' flex={1}>

                        <Skeleton isLoaded={dataLoaded}>
                            <Box bg='#000'>
                              <DataTable
                                  tableData={tableData1}
                                  columnsData={columnsData1}
                                  tableType='CantactList'
                                  hideButtons={true}
                                  userEmail={user.email}
                              />
                            </Box>
                        </Skeleton>
                        </Flex>
                    </Flex>
               
                    
              </Box>
          </Box>
      </Flex>
  )
  
}