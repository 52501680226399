import { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    Skeleton,
    SimpleGrid,
    VStack,
    HStack,
    Divider,
    useToast,
    Modal, 
    Input,
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalCloseButton, 
    ModalBody, 
    ModalFooter,  
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { AiFillPrinter } from "react-icons/ai";
import axios from 'axios';
import DataTable from '../components/AdminDataTable';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';
import { Avatar } from "@chakra-ui/react";
import { useLocation, useParams } from 'react-router-dom';
import { auth, sendPasswordResetEmail }  from '../firebase/firebaseConfig'


export default function Settings() {
    const [isOpen, setIsOpen] = useState(false);
    const [user, _] = useRecoilState(userAtom)

    const mql = window.matchMedia('(max-widt h: 480px)');
    const toast = useToast();
    const [admins, setAdmins] = useState({});
    const [tableData1, setData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);
    const [titleInputValue, setTitleInputValue] = useState("");
    const handleTitleInputChange = (event) => setTitleInputValue(event.target.value);
    const [messageInputValue, setMessageeInputValue] = useState("");
    const handleMessageeInputChange = (event) => setMessageeInputValue(event.target.value);

    const [adminName, setAdminName] = useState("");
    const [adminEmail, setAdminEmail] = useState("");
    const [adminPassword, setAdminPassword] = useState("");
    const [adminPasswordConfirm, setAdminPasswordConfirm] = useState("");
    const [adminRole, setAdminRole] = useState("Admin");

    

    const columnsData1 = [
        {
          Header: "ID",
          accessor: "_id"
        },
        {
          Header: "EMAIL",
          accessor: "email"
        },
        {
          Header: "NAME",
          accessor: "name"
        },
        {
          Header: "ROLE",
          accessor: "role"
        },

      ];
      
  
      const getData = () => {
        axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com/api/admins/all')
            .then(res => {
                // Assuming res.data is an array of user objects
                const filteredAdmins = res.data.filter(admin => admin.email !== user.email);
    
                setAdmins(filteredAdmins);  // If you need to use this state elsewhere, it's now filtered
                setData(filteredAdmins); // Setting filtered data to your state that seems to be used for the table
                setDataLoaded(true);
            })
            .catch(err => {
                console.log(err);
                // Consider setting dataLoaded to true here as well, because the data loading attempt has been completed (even though it was an error)
                setDataLoaded(true);
            });
    }
    

    const createAdmin = () => {
        if (!adminName || !adminEmail || (adminRole !== "Admin" && adminRole !== "Manager") || (adminPassword.length < 6) || (adminPassword != adminPasswordConfirm) ) {
            toast({
                title: "Validation Error.",
                description: "Please fill all the fields correctly.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
    
        createAdminInBackend();
    };

    const createAdminInBackend = () => {
        axios.post('http://127.0.01:8001' + '/api/admin/create', {
            name: adminName,
            email: adminEmail,
            role: adminRole,
            password: adminPassword
        })
        .then(response => {
            handleClose()
            toast({
                title: "Admin Created.",
                description: "The admin was successfully created.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            getData(); // Refresh data
        })
        .catch(error => {
            toast({
                title: "Error.",
                description: "There was an error creating the admin.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        });
    };

                    
    const handlePasswordChange = () => {
        console.log(user); 
        console.log('asdasadasd');
        if (user && user.email) {
            sendPasswordResetEmail(auth, user.email).then(() => {
                toast({
                    title: `Check your email (${user.email}).`,
                    description: "Password reset email has been sent.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            }).catch(error => {
                toast({
                    title: "Error.",
                    description: error.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            });
        } else {
            toast({
                title: "Error.",
                description: "Unable to identify user.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }
            

    
    useEffect(() =>{
        getData();
      }, []) ;
    
      return (
        <Flex w="100%" bg="#000">
            <Sidebar />
            <Box p={4} w='full' pb={0}>
                <Box w='100%' px={5} mb={12}>
                <Text textAlign={'center'} color='#fff' fontSize='2rem' fontWeight='800' justifyContent={'center'}>
                    Admin Settings
                </Text> 
                <Text textAlign={'center'} color='gray.200' fontSize='1rem' fontWeight='500'>
                    Change password, add other admins/subadmins to also access dash w ability to limit permissions.
                </Text>
               
                    <Skeleton isLoaded={dataLoaded}>
                        <SimpleGrid columns={{base: 1, md: 1}} spacing={4} mt={4}>

                            <VStack align="start" spacing={2}  bg='#212121' p={4} borderRadius={8}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff' >Change Password</Text>
                                <HStack spacing="24px">
                                    <VStack align="start">
                                        <Text color='#fff'>An email will be sent to your email address to change your password </Text>
                                        <Button colorScheme="red" onClick={()=> handlePasswordChange()}>Change Password</Button>
                                    </VStack>
                                </HStack>
                            </VStack>

                            <VStack align="start" spacing={2}  bg='#212121' p={4} borderRadius={8}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff'>Add Admins</Text>
                                <HStack spacing="24px">
                                    <VStack align="start">
                                        <Text color='#fff'>Add admin accounts and restrict permissions via roles</Text>
                                        <Text fontSize="xl" fontWeight="semibold" color='#fff'>Roles</Text>
                                        <Text color='#fff'> <b>Admin:</b> Full read access. User Ban permission. Data deletion permission (reviews, photos, etc). Refund Permission. Create Admins permission. Assign roles permission. Blog Writing Permission.</Text>
                                        <Text color='#fff'> <b>Manager:</b> Full read access. Blog Writing permission.</Text>

                                        {user.role == 'Admin' && <Button colorScheme="red"  onClick={handleOpen}>Add Admin</Button>}
                                        <Modal isOpen={isOpen} onClose={handleClose}>
                                            <ModalOverlay />
                                            <ModalContent>
                                                <ModalHeader>New Admin</ModalHeader>
                                                <ModalCloseButton />

                                                <ModalBody>
                                                    <Text>Name</Text>
                                                    <Input mb={2} value={adminName} onChange={(e) => setAdminName(e.target.value)} />
                                                    <Text>Email</Text>
                                                    <Input mb={2} value={adminEmail} type='email' onChange={(e) => setAdminEmail(e.target.value)} />
                                                    <Text>Password</Text>
                                                    <Input mb={2} value={adminPassword} type='password' onChange={(e) => setAdminPassword(e.target.value)} />
                                                    <Text>Confirm Password</Text>
                                                    <Input mb={2} value={adminPasswordConfirm} type='password' onChange={(e) => setAdminPasswordConfirm(e.target.value)} />

                                                    <Text>Role</Text>
                                                    <select mb={2} value={adminRole} onChange={(e) => setAdminRole(e.target.value)}>
                                                        <option value="Admin">Admin</option>
                                                        <option value="Manager">Manager</option>
                                                    </select>
                                                </ModalBody>

                                                <ModalFooter>
                                                    <Button colorScheme="red" mr={3} onClick={createAdmin}>
                                                        Create
                                                    </Button>
                                                    <Button variant="ghost" onClick={handleClose}>Cancel</Button>
                                                </ModalFooter>
                                            </ModalContent>
                                        </Modal>
                                       
                                    </VStack>
                                    
                                </HStack>
                                <Box w={'100%'}>
                                            <DataTable
                                                tableData={tableData1}
                                                columnsData={columnsData1}
                                                tableType='CantactList'
                                                hideButtons={true}
                                                userEmail={''}
                                            />
                                        </Box>
                            </VStack>
                          

                         

                        </SimpleGrid>
                    </Skeleton>
                </Box>
            </Box>
        </Flex>
    )
}