import { useEffect, useState, useRef } from 'react';
import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    Skeleton,
    SimpleGrid,
    VStack,
    HStack,
    Divider,
    AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
    useToast,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { AiFillPrinter } from "react-icons/ai";
import axios from 'axios';
import { Avatar } from "@chakra-ui/react";
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import { useLocation, useParams } from 'react-router-dom';
import DataTable from '../components/BookingDataTable';
import { useNavigate } from 'react-router-dom';



export default function Transaction() {


    const user = useRecoilValue(userAtom);
    const mql = window.matchMedia('(max-widt h: 480px)');

    let mobileView = mql.matches;
    const [client, setClient] = useState([]);
    const [loctician, setLoctician] = useState([]);
    const [transaction, setTransaction] = useState([]);
    const [paymentIntent, setPaymentIntent] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [hideRefundBtn, setHideRefundBtn] = useState(false);
    const { _id } = useParams();
    const history = useNavigate();
    const [isRefundDialogOpen, setIsRefundDialogOpen] = useState(false);
    const onCloseRefundDialog = () => setIsRefundDialogOpen(false);
    const cancelRef = useRef();
    
    const openRefundDialog = () => {
      setIsRefundDialogOpen(true);
    };
  
  
    const getData = () => {
        const req = axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com' + `/api/transaction/data/${_id}`).then(res => {

            setClient(res.data.user)
            setLoctician(res.data.loctician)
            // setPaymentIntent(res.data.paymentIntent)
            setTransaction(res.data.transaction)

            console.log(res.data.transaction);
            // console.log(res.data.paymentIntent);
    
          setDataLoaded(true);
        }).catch(err => {
          console.log(err);
        });
    }

    const toast = useToast();

    // const issueRefund = async () => {
    // try {
    //     await axios.post('https://locseat-backend-e47bf5c07959.herokuapp.com/api/stripe/create-refund', {
    //     payment_intent_id: paymentIntent.id,
    //     });
    //     onCloseRefundDialog();
    //     // Here, you could add code to update the status of the paymentIntent or refresh the page.

    //     toast({
    //     title: "Success.",
    //     description: "The refund has been successfully issued.",
    //     status: "success",
    //     duration: 9000,
    //     isClosable: true,
    //     });
    //     setHideRefundBtn(true)

    // } catch (error) {
    //     console.error(error);

    //     toast({
    //     title: "An error occurred.",
    //     description: "Unable to issue a refund.",
    //     status: "error",
    //     duration: 9000,
    //     isClosable: true,
    //     });
    // }
    // };


    useEffect(() =>{
        getData();
      }, []) ;
    


      return (
        <Flex w="100%" bg={'#000'} >
            <Sidebar />
            <Box p={4} w='full' pb={0} >
                <Box w='100%' px={5} mb={12}>
                    <Text textAlign={'left'} color='#fff' fontSize='2rem' fontWeight='800'>
                        Transaction ID: {_id}
                    </Text>
                    <Skeleton isLoaded={dataLoaded}>
                        <SimpleGrid columns={{base: 1, md: 2}} spacing={4} mt={4}>

                        <VStack align="start" spacing={2}  bg='#212121' p={4} borderRadius={8} cursor={'pointer'} onClick={() => history(`/user/${client._id}`)}>
                                <Text fontSize="xl" color='#fff'fontWeight="semibold">Client Details</Text>
                                <HStack spacing="24px">
                                    <Avatar 
                                        src={client.profilePic}
                                        name={client.fullName}
                                        fallbackSrc="https://via.placeholder.com/150"
                                    />  
                                    <VStack align="start">
                                        <Text color='#fff'> <b>Name:</b> {client.fullName}</Text>
                                        <Text color='#fff'> <b>Email:</b> {client.email}</Text>
                                        <Text color='#fff'> <b>Phone:</b> {client.phone}</Text>
                                        {/* <Text color='#fff'> <b>Stripe Customer ID:</b> {paymentIntent.customer}</Text> */}
                                    </VStack>
                                </HStack>
                            </VStack>

                            <VStack align="start" spacing={2}  bg='#212121' p={4} borderRadius={8} cursor={'pointer'} onClick={() => history(`/loctician/${loctician._id}`)}>
                                <Text color='#fff' fontSize="xl" fontWeight="semibold">Loctician Details</Text>
                                <HStack spacing="24px">
                                    <Avatar 
                                        src={loctician.coverPhoto}
                                        name={loctician.fullName}
                                        fallbackSrc="https://via.placeholder.com/150"
                                    />
                                    <VStack align="start">
                                        <Text color='#fff'> <b>Name:</b> {loctician.fullName}</Text>
                                        <Text color='#fff'> <b>Email:</b> {loctician.email}</Text>
                                        <Text color='#fff'> <b>Shop Name:</b> {loctician.phone}</Text>
                                    </VStack>
                                </HStack>
                            </VStack>

                            {/* <VStack align="start" spacing={2} bg='#212121' p={4} borderRadius={8}>
                                <Text color='#fff' fontSize="xl" fontWeight="semibold">Payment Details</Text>
                                <Text color='#fff'> <b>Amount:</b> ${(paymentIntent.amount/100).toFixed(2)}</Text>
                                <Text color='#fff'> <b>Status:</b> {paymentIntent.status?.charAt(0).toUpperCase() + paymentIntent.status?.slice(1)}</Text>
                            </VStack> */}

                            {/* <VStack align="start" spacing={2}  bg='#212121' p={4} borderRadius={8}>
                                <Text color='#fff' fontSize="xl" fontWeight="semibold">Transaction Details</Text>
                                <AlertDialog
                                    isOpen={isRefundDialogOpen}
                                    leastDestructiveRef={cancelRef}
                                    onClose={onCloseRefundDialog}
                                    >
                                    <AlertDialogOverlay>
                                        <AlertDialogContent>
                                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                            Issue Refund
                                        </AlertDialogHeader>

                                        <AlertDialogBody>
                                            Are you sure you want to refund this payment? This operation cannot be undone.
                                        </AlertDialogBody>

                                        <AlertDialogFooter>
                                            <Button ref={cancelRef} onClick={onCloseRefundDialog}>
                                            Cancel
                                            </Button>
                                            <Button colorScheme="red"  onClick={issueRefund} ml={3}>
                                            Refund
                                            </Button>
                                        </AlertDialogFooter>
                                        </AlertDialogContent>
                                    </AlertDialogOverlay>
                                    </AlertDialog>

                                <Text color='#fff'> <b>Payment Intent ID:</b> {paymentIntent.id}</Text>
                                <Text color='#fff'> <b>Amount:</b> ${(paymentIntent.amount/100).toFixed(2)}</Text>
                                <Text color='#fff'> <b>Status:</b> {paymentIntent.status}</Text>
                                {paymentIntent.status == 'succeeded' && !hideRefundBtn && user.role == 'Admin' && <Button colorScheme="red" onClick={openRefundDialog}>Issue Refund</Button>}


                            </VStack>
                             */}
                        </SimpleGrid>
                    </Skeleton>
                </Box>
            </Box>
        </Flex>
    );
}