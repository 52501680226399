import { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Image,
    Skeleton,
    SimpleGrid,
    VStack,
    HStack,
    Divider,
    Collapse, Button, useToast,
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalCloseButton, 
    ModalBody, 
    ModalFooter, 
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { AiFillPrinter } from "react-icons/ai";
import axios from 'axios';
import DataTable from '../components/UserDataTable2';
import DataTable2 from '../components/TransactionsDataTable';
import DataTable3 from '../components/ReviewsDataTable';
import { Avatar } from "@chakra-ui/react";
import { useLocation, useParams } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';
 

export default function Loctician() {
    const mql = window.matchMedia('(max-widt h: 480px)');
    const { _id } = useParams();
    const [isOpen, setIsOpen] = useState(false);

    const onClose = () => setIsOpen(false);
    const onOpen = () => setIsOpen(true);
    const [user, setUser] = useState({});
    const [reviews, setReviews] = useState([]);
    const [loctician, setLoctician] = useState({});
    const [services, setServices] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showService, setShowService] = useState({});
    const toast = useToast();

    const [userObj, _] = useRecoilState(userAtom)
    
    const [currentPage, setCurrentPage] = useState(1);
    const servicesPerPage = 5;

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    const handleImageClick = (image) => {
        setCurrentImage(image);
        setViewModalOpen(true);
    }

    const handleDeleteClick = () => {
        setViewModalOpen(false);
        setDeleteModalOpen(true);
    }

    const handleDeleteConfirm = () => {
        deleteImage(currentImage.id);
        setDeleteModalOpen(false);
    }

    const deleteImage = (id) => {
        // This is the URL of your server-side function
        const url = 'https://locseat-backend-e47bf5c07959.herokuapp.com/api/loctician/delete-photo';
      
        // This is the data that will be sent to the server-side function
        const data = {
            _id: loctician._id,
            photo_id: id
        };
    
        axios.delete(url, { data: data })
            .then((response) => {
                if (response.data.success) {
                    toast({
                        title: "Photo deleted successfully.",
                        description: "The photo has been removed from the user's profile.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
    
                    // Close the modals
                    setViewModalOpen(false);
                    setDeleteModalOpen(false);
    
                    // Refresh the images on the page
                    getData(); // Assuming you have a function like this to fetch the data again
    
                } else {
                    toast({
                        title: "Failed to delete photo.",
                        description: response.data.error,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            })
            .catch((error) => {
                toast({
                    title: "Failed to delete photo.",
                    description: "An error occurred. Please try again later.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            });
    }
    
  
  
    const getData = () => {
        const req = axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com' + `/api/loctician/data/${_id}`).then(res => {
            let app = res.data.appointments;
    
            app = app.map(appointment => {
                // check each field if it exists and convert true --> Y, false --> N
                if ("confirmed" in appointment) {
                    appointment.confirmed = appointment.confirmed ? "Y" : "N";
                }
                if ("canceled" in appointment) {
                    appointment.canceled = appointment.canceled ? "Y" : "N";
                }
                if ("reviewed" in appointment) {
                    appointment.reviewed = appointment.reviewed ? "Y" : "N";
                }
                return appointment;
            });

            setUser(res.data.user)
            setReviews(res.data.reviews)
            setAppointments(app)
            setTransactions(res.data.transactions)
            setLoctician(res.data.loctician)
            console.log(res.data.loctician.photos);
            setServices(res.data.services)
            
 
    
          setDataLoaded(true);
        }).catch(err => {
          console.log(err);
        });
    }
    

    const banUser = () => {
        axios.post('https://locseat-backend-e47bf5c07959.herokuapp.com/api/user/ban',  {_id: _id})
            .then(res => {
                toast({
                    title: "Action Complete.",
                    description: `User ${_id} ban status has been updated.`,
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
                getData();
                onClose();
            })
            .catch(err => {
                console.log(err);
                toast({
                    title: "An error occurred.",
                    description: "Unable to update user ban status.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            });
    };

    

    useEffect(() =>{
        getData();
      }, []) ;
    
      const columnsData1 = [
        {
          Header: "ID",
          accessor: "_id"
        },
        {
          Header: "Date Created",
          accessor: "dateCreated"
        },
        {
          Header: "Appointment Date",
          accessor: "appointmentDate"
        },
        {
            Header: "Client",
            accessor: "clientID"
        },
        {
          Header: "Conmfirmed",
          accessor: "confirmed"
        },
        {
            Header: "Canceled",
            accessor: "canceled"
        },
        {
            Header: "Reviewed",
            accessor: "reviewed"
        },
      
      ];

      const columnsData2 = [
        {
          Header: "ID",
          accessor: "_id"
        },
        {
            Header: "Total",
            accessor: "totalCost"
          },
        {
          Header: "Date Created",
          accessor: "dateCreated"
        },
        {
          Header: "Appointment Date",
          accessor: "appointmentDate"
        },
        {
            Header: "Loctician",
            accessor: "locticianID"
        },
        {
            Header: "Payment ID",
            accessor: "paymentIntentID"
        },
      
      ];

      
      const columnsData3 = [
     
        {
            Header: "Date",
            accessor: "date"
          },
        {
          Header: "Rating",
          accessor: "rating"
        },
        {
          Header: "Review",
          accessor: "review"
        },
        {
            Header: "Loctician",
            accessor: "locticianID"
        },
      ];

      const handleToggle = (id) => {
        setShowService(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    }

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const nextPage = () => {
        if (currentPage < Math.ceil(services.length / servicesPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    }

    const indexOfLastService = currentPage * servicesPerPage;
    const indexOfFirstService = indexOfLastService - servicesPerPage;
    const currentServices = services.slice(indexOfFirstService, indexOfLastService);


      return (
        <Flex w="100%" bg="#000">
            <Sidebar />
            <Box p={4} w='full' pb={0}>
                <Box w='100%' px={5} mb={12}>
                    <Text textAlign={'left'} color='#fff' fontSize='2rem' fontWeight='800'>
                    Loctician ID: {_id} {user?.banned == 'banned' && '(Banned)'}
                    </Text>
                    {user?.banned == 'banned' 
                        ?
                        <>
                        {userObj.role == 'Admin' && <Button colorScheme="red" onClick={onOpen}>Unban User</Button>}
                        </>
                        :
                        <>
                        {userObj.role == 'Admin' && <Button colorScheme="red" onClick={onOpen}>Ban User</Button>}
                        </>

                    }
                    <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Confirm Ban</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                Are you sure you want to {user?.banned == 'banned'? 'unban' : 'ban'} this user?
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="red" mr={3} onClick={banUser}>
                                    Yes
                                </Button>
                                <Button variant="ghost" onClick={onClose}>No, Cancel</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    <Skeleton isLoaded={dataLoaded}>
                        <SimpleGrid columns={{base: 2, md: 2}} spacing={4} mt={4}>

                        <VStack align="start" spacing={2}  bg='#212121' p={4} borderRadius={8}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff'>Loctician Details</Text>
                                <HStack spacing="24px">
                                    <Avatar 
                                        src={loctician?.coverPhoto}
                                        name={`${user?.fullName}`}
                                        size={'lg'}
                                        fallbackSrc="https://via.placeholder.com/150"
                                    />
                                    <VStack align="start">
                                        <Text color='#fff'> <b>Name:</b> {`${user?.fullName}`}</Text>
                                        <Text color='#fff'> <b>Email:</b> {user.email}</Text>
                                        <Text color='#fff'> <b>Phone:</b> {user.phone}</Text>
                                        <Text color='#fff'> <b>Shop Name:</b> {loctician.shopName}</Text>
                                        <Text color='#fff'> <b>Policy:</b> {loctician.policy}</Text>
                                        <Text color='#fff'> <b>Cancellation Fee:</b> {loctician.cancellationFee}</Text>
                                        <Text color='#fff'> <b>Stripe Account ID:</b> {loctician.stripeAcctID}</Text>
                                    </VStack>

                                    
                                </HStack>
                            </VStack>

                            <VStack align="start" spacing={2}  bg='#212121' p={4} borderRadius={8}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff'>Services</Text>
                                {currentServices.map(service => (
                                    <Box key={service._id}>
                                        <Button onClick={() => handleToggle(service._id)} variant="outline" size="sm" bg={'#303030'}>
                                            <Text color='#fff'> <b>Service ID:</b> {service._id}</Text>
                                        </Button>
                                        <Collapse in={showService[service._id]}>
                                            <Text ml={4} color='gray.100'><b>Title:</b> {service.title}</Text>
                                            <Text ml={4} color='gray.100'><b>Description:</b> {service.description}</Text>
                                            <Text ml={4} color='gray.100'><b>Duration:</b> {service.duration} min</Text>
                                            <Text ml={4} color='gray.100'><b>Price:</b> ${service.price}</Text>
                                        </Collapse>
                                    </Box>
                                ))}
                                <Flex justifyContent={'space-between'}>
                                {currentPage != 1 && 
                                <Button onClick={prevPage} disabled={currentPage === 1}>Previous</Button>
                                }
                                {
                                currentPage != Math.ceil(services.length / servicesPerPage) && 
                                <Button onClick={nextPage} disabled={currentPage === Math.ceil(services.length / servicesPerPage)}>Next</Button>
                                }
                                </Flex>
                            </VStack>                
                                        
                        </SimpleGrid>
                    </Skeleton>

                    <Skeleton isLoaded={dataLoaded}>
                        <SimpleGrid columns={{base: 1, md: 1}} spacing={4} mt={4}>

                        <VStack align="start" spacing={2}  bg='#212121' p={4} borderRadius={8}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff'>Photos</Text>
                                <Box overflowX="scroll" display="flex" w='100%'>
                                <style>
                                    {`
                                        ::-webkit-scrollbar {
                                            width: 12px;
                                        }

                                        ::-webkit-scrollbar-track {
                                            background: #212121;
                                        }

                                        ::-webkit-scrollbar-thumb {
                                            background-color: black;
                                            border-radius: 20px;
                                        }
                                    `}
                                </style>
                                    {loctician.photos && loctician.photos.map(photo => (
                                        <Image
                                            key={photo.id}
                                            boxSize="12rem"
                                            objectFit="cover"
                                            cursor={'pointer'}
                                            src={photo.imageUri}
                                            onClick={() => handleImageClick(photo)}
                                            alt="Photo"
                                            mr={2}
                                        />
                                    ))}
                                </Box>
                            </VStack>

                            <Modal isOpen={viewModalOpen} onClose={() => setViewModalOpen(false)} >
                                <ModalOverlay />
                                <ModalContent bg='#212121'> 
                                    <ModalHeader></ModalHeader>
                                    <ModalCloseButton bg='gray.500' />
                                    <ModalBody>
                                        <Image boxSize="400px" objectFit="cover" src={currentImage?.imageUri} alt={`photo-${currentImage?.id}`} />
                                    </ModalBody>
                                    <ModalFooter >
                                        
                                    {userObj.role == 'Admin' && 
                                        <Button colorScheme="red" mr={3} onClick={handleDeleteClick} m={'0 auto'}>
                                            Delete Photo
                                        </Button>
                                    }
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>

                            <Modal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Confirm Delete</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        Are you sure you want to delete this image?
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button colorScheme="red" mr={3} onClick={handleDeleteConfirm}>
                                            Yes, Delete
                                        </Button>
                                        <Button variant="ghost" onClick={() => setDeleteModalOpen(false)}>No, Cancel</Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>

                            

                        <Text color='#fff'> <b>Appointments</b></Text>
                            {appointments.length > 0 ? (
                                <DataTable
                                    tableData={appointments}
                                    columnsData={columnsData1}
                                    tableType='CantactList'
                                    hideButtons={true}
                                    userEmail={user.email}
                                />
                            ) : (
                                <Text color='#fff'> No Appointments</Text>
                            )}

                            <Text color='#fff'> <b>Transactions</b></Text>
                            {transactions.length > 0 ? (
                                <DataTable2
                                    tableData={transactions}
                                    columnsData={columnsData2}
                                    tableType='CantactList'
                                    hideButtons={true}
                                    userEmail={user.email}
                                />
                            ) : (
                                <Text color='#fff'> No Transactions</Text>
                            )}

                            <Text color='#fff'> <b>Reviews</b></Text>
                            {reviews.length > 0 ? (
                                <DataTable3
                                    tableData={reviews}
                                    columnsData={columnsData3}
                                    tableType='CantactList'
                                    hideButtons={true}
                                    userEmail={user.email}
                                />
                            ) : (
                                <Text color='#fff'> No Reviews</Text>
                            )}            

                        </SimpleGrid>
                    </Skeleton>
                </Box>
            </Box>
        </Flex>
    )
}