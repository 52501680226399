import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    Skeleton,
    Grid,
    VStack,
    Tooltip,
} from '@chakra-ui/react';

import { AiFillPrinter } from "react-icons/ai";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import { useLocation } from 'react-router-dom';
import DataTable from '../components/SubscriptionsDataTable';

export default function Subscriptions() {


    const user = useRecoilValue(userAtom);
    const mql = window.matchMedia('(max-width: 480px)');

    let mobileView = mql.matches;
    const [tableData1, setData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [dataLoaded2, setDataLoaded2] = useState(false);
    const [campaignName, setCampaignName] = useState();
    const [stats, setStats] = useState({});
  
  
    const getData = () => {
        const req = axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com' + "/api/users/all/locticians").then(res => {
          let users = res.data;
    
          users = users.map(user => {
            // check each field if it exists and convert true --> Y, false --> N
            if ("isLoctician" in user) {
                user.isLoctician = user.isLoctician ? "Y" : "N";
            }

            return user;
          });
    
          setData(users);
          setDataLoaded(true);
        }).catch(err => {
          console.log(err);
        });
    }
    

    useEffect(() =>{
        getData();
      }, []) ;
    



    const columnsData1 = [
        {
          Header: "ID",
          accessor: "_id"
        },
        {
          Header: "Email",
          accessor: "email"
        },
        {
            Header: "Phone",
            accessor: "phone"
        },
        {
          Header: "Name",
          accessor: "fullName"
        },
  
      
      ];
      


  return (
    <Flex w="100%" bg='#000'>
      <Sidebar />
      <Box p={4} w='full' pb={0}>

        <Box w='100%'>
            <Text textAlign={'center'} color='#fff' fontSize='2rem' fontWeight='800'>Subscriptions</Text>
            <Text textAlign={'center'} color='gray.200' fontSize='1rem' fontWeight='500' mb={6}>
                View a summary of all active, expired, and canceled subscriptions. View subscription details, such as the user, subscription plan, start and end dates, and payment status.
            </Text>

            <Box w='100%' px={6} mb={6}>
                    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                          <Box bg='#212121' p={4} borderRadius={8} height="fit-content" _hover={{ boxShadow: '0px 4px 30px #883D1A' }}>
                            <VStack spacing={2} align="center" justify="center" color="#fff" fontSize="lg" >
                                <Tooltip label='Total active subscriptions.'>
                                    <Text textAlign={'center'} fontWeight={'extrabold'} mb={6}>Active Subscriptions</Text>
                                </Tooltip>

                              <Flex flex={1} textAlign={'center'} mb={4}>
                                  <Text fontSize={'1rem'} whiteSpace="nowrap" fontWeight={'bold'}>1,203</Text> 
                                
                              </Flex>

                           
                            </VStack>
                          </Box>



                          <Box bg='#212121' p={4} borderRadius={8}  height="fit-content" _hover={{ boxShadow: '0px 4px 20px #883D1A' }}>
                            <VStack spacing={2} align="center" justify="center" color="#fff" fontSize="lg" >
                                <Tooltip label='Amount of subscriptions that have been either cancelled or expired.'>
                                 <Text textAlign={'center'} fontWeight={'extrabold'} mb={6} whiteSpace="nowrap">Cancelled Subscriptions</Text>
                                </Tooltip>


                              <Flex flex={1} textAlign={'center'} mb={4}>
                                  <Text fontSize={'1rem'} whiteSpace="nowrap" fontWeight={'bold'}>287</Text> 
                                
                              </Flex>

                            

                            </VStack>
                          </Box>

                    </Grid>
                  </Box>

            <Box w='100%' mb={6}>
                <Flex>
                    <Flex direction='column' flex={1}>

                    <Skeleton isLoaded={dataLoaded}>
                        <Box>
                            <DataTable
                                tableData={tableData1}
                                columnsData={columnsData1}
                                tableType='CantactList'
                                hideButtons={true}
                                userEmail={user.email}
                            />
                        </Box>
                    </Skeleton>
                    </Flex>

                </Flex>
            </Box>
        </Box>
      </Box>
    </Flex>
  )
}