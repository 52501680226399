import { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    Skeleton,
    SimpleGrid,
    VStack,
    HStack,
    Divider,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { AiFillPrinter } from "react-icons/ai";
import axios from 'axios';
import { Avatar } from "@chakra-ui/react";
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import { useLocation, useParams } from 'react-router-dom';
import DataTable from '../components/BookingDataTable';
import { useNavigate } from 'react-router-dom';



export default function Review() {


    const user = useRecoilValue(userAtom);
    const mql = window.matchMedia('(max-widt h: 480px)');

    let mobileView = mql.matches;
    const [review, setReview] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { _id } = useParams();
    const history = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);
    const handleInputChange = (event) => setInputValue(event.target.value);

  
  
    const getData = () => {
        const req = axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com' + `/api/review/data/${_id}`).then(res => {

            setReview(res.data.review)
    
          setDataLoaded(true);
        }).catch(err => {
          console.log(err);
        });
    }
    

    useEffect(() =>{
        getData();
      }, []) ;
    


      const deleteReview = () => {
        if (inputValue !== _id) {
            alert('Review ID does not match!');
            return;
        }
    
        axios.delete('https://locseat-backend-e47bf5c07959.herokuapp.com' + `/api/review/delete/${inputValue}`)
            .then(res => {
                console.log(res.data);
                history('/');
            }).catch(err => {
                console.log(err);
            });
    
        handleClose();
    }
      


      return (
        <Flex w="100%" bg="#000">
            <Sidebar />
            <Box p={4} w='full' pb={0}>
                <Box w='100%' px={5} mb={12}>
                    <Text textAlign={'left'} color='#fff' fontSize='2rem' fontWeight='800'>
                        Review ID: {_id}
                    </Text>
                    {user.role == "Admin" && 
                        <Button colorScheme="red" onClick={handleOpen}>Delete Review</Button>
                    }
                    <Modal isOpen={isOpen} onClose={handleClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Confirm Deletion</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Text fontWeight={600} mb={2}>Once this review is deleted it cannot be restored</Text>
                                <Text mb={2}>Please enter the ID of the review to confirm deletion:</Text>
                                <Text mb={2}>{_id}</Text>
                                <Input value={inputValue} onChange={handleInputChange} />
                            </ModalBody>

                            <ModalFooter>
                                <Button colorScheme="red" mr={3} onClick={deleteReview}>
                                    Confirm
                                </Button>
                                <Button variant="ghost" onClick={handleClose}>Cancel</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    <Skeleton isLoaded={dataLoaded}>
                        <VStack align="start" spacing={6}  bg='#212121' p={6} m={4} borderRadius={8}>
                            <Text fontSize="xl" fontWeight="semibold" color='#fff'>Review Details</Text>
                            <Text color='#fff'> <b>Date:</b> {review.date}</Text>
                            <Text color='#fff'> <b>First Name:</b> {review.firstName}</Text>
                            <Text color='#fff'> <b>Last Name:</b> {review.lastName}</Text>
                            <Text color='#fff'> <b>Rating:</b> {review.rating}</Text>
                            <Text color='#fff'> <b>Review:</b> {review.review}</Text>
                        </VStack>

                        <VStack align="start" spacing={2}  bg='#212121' p={4} m={4} borderRadius={8} cursor={'pointer'} onClick={() => history(`/user/${review.userID}`)}>
                            <Text fontSize="xl" fontWeight="semibold" color='#fff'>User Details</Text>
                            <Text color='#fff'> <b>User ID:</b> {review.userID}</Text>
                        </VStack>

                        <VStack align="start" spacing={2}  bg='#212121' p={4} m={4} borderRadius={8} cursor={'pointer'} onClick={() => history(`/loctician/${review.locticianID}`)}>
                            <Text fontSize="xl" fontWeight="semibold" color='#fff'>Loctician Details</Text>
                            <Text color='#fff'> <b>Loctician ID:</b> {review.locticianID}</Text>
                        </VStack>
                    </Skeleton>
                </Box>
            </Box>
        </Flex>
    );
}