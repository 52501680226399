import { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    Input,
    Skeleton,
    SimpleGrid,
    VStack,
    HStack,
    Divider,
    useToast,
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalCloseButton, 
    ModalBody, 
    ModalFooter,  

} from '@chakra-ui/react';
import { format } from 'date-fns';
import { AiFillPrinter } from "react-icons/ai";
import axios from 'axios';
import DataTable from '../components/UserDataTable2';
import DataTable2 from '../components/TransactionsDataTable';
import DataTable3 from '../components/ReviewsDataTable';
import { Avatar } from "@chakra-ui/react";
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';

export default function Admin() {

    const onClose = () => setIsOpen(false);
    const onOpen = () => setIsOpen(true);
    const mql = window.matchMedia('(max-widt h: 480px)');
    const { _id } = useParams();
    const history = useNavigate();
    const toast = useToast();
    const [admin, setAdmin] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const [user, _] = useRecoilState(userAtom)
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);
    const handleInputChange = (event) => setInputValue(event.target.value);
    const [selectedRole, setSelectedRole] = useState(admin.role || 'Admin');  // default to 'admin' if undefined

    

  
  
    const getData = () => {
        const req = axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com' + `/api/admin/${_id}`).then(res => {
          setAdmin(res.data)
          setSelectedRole(res.data.role)
          setDataLoaded(true);
        }).catch(err => {
          console.log(err);
        });
    }

    const deleteAdmin = () => {
        if (inputValue !== _id) {
            // alert('Admin ID does not match!');
            toast({
                title: "Error deleting admin",
                description: "Admin ID does not match",
                status: "error",
                duration: 5000,
                isClosable: true,
            });

            return;
        }
    
        axios.delete('https://locseat-backend-e47bf5c07959.herokuapp.com' + `/api/admin/delete/${inputValue}`)
            .then(res => {
                console.log(res.data);
                history('/settings');
            }).catch(err => {
                console.log(err);
            });
    
        handleClose();
    }
    
    const updateRole = () => {
        axios.put('https://locseat-backend-e47bf5c07959.herokuapp.com' + `/api/admin/role/${_id}`, { role: selectedRole })
            .then(res => {
                toast({
                    title: "Role updated successfully.",
                    description: "Admin role has been updated.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                getData(); // Refresh the data
            })
            .catch(err => {
                console.log(err);
                toast({
                    title: "Error updating role.",
                    description: "Failed to update the admin role.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            });
    }
    



    useEffect(() =>{
        getData();
      }, []) ;
    
      return (
        <Flex w="100%" bg="#000">
            <Sidebar />
            <Box p={4} w='full' pb={0}>
                <Box w='100%' px={5} mb={12}>

                {user.role == 'Admin' 
                ?
                <>
                <Skeleton isLoaded={dataLoaded}>
                    <Text textAlign={'left'} color='#fff' fontSize='2rem' fontWeight='800'>
                        Admin: {admin.email}
                    </Text>
                    <Button colorScheme="red" onClick={handleOpen}>Delete Admin</Button>
                    <Modal isOpen={isOpen} onClose={handleClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Confirm Deletion</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Text fontWeight={600} mb={2}>Once this admin account is deleted it cannot be restored</Text>
                                <Text mb={2}>Please enter the ID of the account to confirm deletion:</Text>
                                <Text mb={2}>{_id}</Text>
                                <Input value={inputValue} onChange={handleInputChange} />
                            </ModalBody>

                            <ModalFooter>
                                <Button colorScheme="red" mr={3} onClick={deleteAdmin}>
                                    Confirm
                                </Button>
                                <Button variant="ghost" onClick={handleClose}>Cancel</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Skeleton>
                  
                <Skeleton isLoaded={dataLoaded}>
                    <SimpleGrid columns={{base: 1, md: 1}} spacing={4} mt={4}>

                        <VStack align="start" spacing={2}  bg='#212121' p={4} borderRadius={8}>
                            <Text fontSize="xl" fontWeight="semibold" color='#fff'>Admin Details</Text>
                            <HStack spacing="24px">
                                
                                <VStack align="start">
                                    <Text color='#fff'> <b>Name:</b> {admin.name}</Text>
                                    <Text color='#fff'> <b>Email:</b> {admin.email}</Text>
                                    <Text color='#fff'> <b>Role:</b> <select value={selectedRole} style={{backgroundColor:'#212121'}} onChange={(e) => setSelectedRole(e.target.value)}>
                                        <option value="Admin">Admin</option>
                                        <option value="Manager">Manager</option>
                                    </select></Text>
                                    
                                    <Button colorScheme='blackAlpha' variant={'outline'} color={'white'} onClick={updateRole}>Update Role</Button>
                                </VStack>
                            </HStack>
                        </VStack>
                        

                    </SimpleGrid>
                </Skeleton>
                </>
                :<>
                <Text textAlign={'center'} mt={'30vh'} color='#fff' fontSize='2rem' fontWeight='800'>
                    You do not have permission to view this page
                </Text>
                </>
                }
                </Box>
            </Box>
        </Flex>
    )
}