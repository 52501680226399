import { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    Skeleton,
    SimpleGrid,
    VStack,
    HStack,
    Divider

} from '@chakra-ui/react';
import { format } from 'date-fns';
import { AiFillPrinter } from "react-icons/ai";
import axios from 'axios';
import { Avatar } from "@chakra-ui/react";
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import { useLocation, useParams } from 'react-router-dom';
import DataTable from '../components/BookingDataTable';
import { useNavigate } from 'react-router-dom';



export default function Booking() {


    const user = useRecoilValue(userAtom);
    const mql = window.matchMedia('(max-widt h: 480px)');

    let mobileView = mql.matches;
    const [appointment, setAppointment] = useState([]);
    const [client, setClient] = useState([]);
    const [loctician, setLoctician] = useState([]);
    const [transaction, setTransaction] = useState([]);
    // const [paymentIntent, setPaymentIntent] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { _id } = useParams();
    const history = useNavigate();

  
  
    const getData = () => {
        const req = axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com' + `/api/appointments/${_id}`).then(res => {

            setAppointment(res.data.appointment)
            setClient(res.data.client)
            setLoctician(res.data.loctician)
            // setPaymentIntent(res.data.paymentIntent)
            setTransaction(res.data.transaction)

            // console.log(res.data.transaction);
    
          setDataLoaded(true);
        }).catch(err => {
          console.log(err);
        });
    }
    

    useEffect(() =>{
        getData();
      }, []) ;
    



      


      return (
        <Flex w="100%" bg="#000">
            <Sidebar />
            <Box p={4} w='full' pb={0}>
                <Box w='100%' px={5} mb={12}>
                    <Text textAlign={'left'} color='#fff' fontSize='2rem' fontWeight='800'>
                        Booking ID: {_id}
                    </Text>
                    <Skeleton isLoaded={dataLoaded}>
                        <SimpleGrid columns={{base: 1, md: 2}} spacing={4} mt={4}>

                        <VStack align="start" spacing={2}  bg='#212121' p={4} borderRadius={8} cursor={'pointer'} onClick={() => history(`/user/${client._id}`)}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff'>Client Details</Text>
                                <HStack spacing="24px">
                                    <Avatar 
                                        src={client.profilePic}
                                        name={client.fullName}
                                        fallbackSrc="https://via.placeholder.com/150"
                                    />
                                    <VStack align="start">
                                        <Text color='#fff'> <b>Name:</b> {client.fullName}</Text>
                                        <Text color='#fff'> <b>Email:</b> {client.email}</Text>
                                        <Text color='#fff'> <b>Phone:</b> {client.phone}</Text>
                                    </VStack>
                                </HStack>
                        </VStack>

                            <VStack align="start" spacing={2}  bg='#212121' p={4} borderRadius={8} cursor={'pointer'} onClick={() => history(`/loctician/${loctician._id}`)}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff'>Loctician Details</Text>
                                <HStack spacing="24px">
                                    <Avatar 
                                        src={loctician.coverPhoto}
                                        name={loctician.fullName}
                                        fallbackSrc="https://via.placeholder.com/150"
                                    />
                                    <VStack align="start">
                                        <Text color='#fff'> <b>Name:</b> {loctician.fullName}</Text>
                                        <Text color='#fff'> <b>Email:</b> {loctician.email}</Text>
                                        <Text color='#fff'> <b>Shop Name:</b> {loctician.shopName}</Text>
                                    </VStack>
                                </HStack>
                            </VStack>

                            

                            

                            {/* <VStack align="start" spacing={2} bg='#212121' p={4} borderRadius={8} cursor={'pointer'} onClick={() => history(`/transaction/${transaction._id}`)}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff'>Payment Details</Text>
                                <Text color='#fff'> <b>Amount:</b> ${(paymentIntent.amount/100).toFixed(2)}</Text>
                                <Text color='#fff'> <b>Status:</b> {paymentIntent.status?.charAt(0).toUpperCase() + paymentIntent.status?.slice(1)}</Text>
                            </VStack>

                            <VStack align="start" spacing={2}  bg='#212121' p={4} borderRadius={8} cursor={'pointer'} onClick={() => history(`/transaction/${transaction._id}`)}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff'>Transaction Details</Text>
                                <Text color='#fff'> <b>Payment Intent ID:</b> {paymentIntent.id}</Text>
                                <Text color='#fff'> <b>Amount:</b> ${(paymentIntent.amount/100).toFixed(2)}</Text>
                            </VStack> */}

                            <VStack align="start" spacing={2} bg='#212121' p={4} borderRadius={8}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff'>Appointment Details</Text>
                                <Text color='#fff'> <b>Date Created:</b> {appointment.dateCreated}</Text>
                                <Text color='#fff'> <b>Appointment Date:</b> {appointment.appointmentDate}</Text>
                                <Text color='#fff'> <b>Duration:</b> {transaction.duration} minutes</Text>'
                                <Text color='#fff'> <b>Shop Name:</b> {loctician.shopName}</Text>
                                {/* <Text color='#fff'> <b>Date:</b> {format(new Date(appointment.appointmentDate), 'PPpp')}</Text> */}
                                {/* <Text color='#fff'> <b>Services:</b> {appointment.services.join(', ')}</Text> */}
                                <Text color='#fff'> <b>Status:</b> {appointment.confirmed ? 'Confirmed' : 'Not confirmed'}</Text>
                                <Text color='#fff'> <b>Reviewed:</b> {appointment.reviewed ? 'Yes' : 'No'}</Text>
                                <Text color='#fff'> <b>Cancelled:</b> {appointment.canceled ? 'Yes' : 'No'}</Text>
                                <Text fontSize={'1.2rem'} color='#fff' fontWeight='600'>Services: {appointment.services ? appointment.services.join(", ") : "No services defined"}</Text>
                            </VStack>

                            

                            
                        </SimpleGrid>
                    </Skeleton>
                </Box>
            </Box>
        </Flex>
    );
}