import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
    Box, 
    VStack, 
    Text, 
    HStack, 
    Flex ,
    Icon, 
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Heading,
    useDisclosure,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button,

} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import { useToast } from '@chakra-ui/react';
import { FaReply, FaAdjust, FaEllipsisH} from "react-icons/fa";  // Added icons for Delete and Forward
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';  // Import styles


function Toolbar({ status,  onOpenDeleteModal, onReplyClick}) {
    const toast = useToast();
    const { _id } = useParams(); // get conversation id from URL


    const handleChangeStatus = (newStatus) => {
        axios.patch(`https://locseat-backend-e47bf5c07959.herokuapp.com` + `/api/conversation-details/${_id}/status`, {
            status: newStatus
        })
        .then(response => {
            if (response.status === 204) {
                toast({
                    title: "Status Updated",
                    description: "Conversation status updated successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            }
        })
        .catch(error => {
            toast({
                title: "Error",
                description: "Failed to update conversation status.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            console.error("Failed to update status:", error);
        });
    };
  return (
    <Box p={4} borderRadius={8}>
      <HStack spacing={5}>
        {/* Reply Tool */}
        <Tooltip label="Reply" aria-label="Reply tooltip">
          <Box as="button" p={2} borderRadius="md" bg="#000" _hover={{ bg: "#333" }} onClick={() => onReplyClick()}>
            <Icon as={FaReply} color="white" />
          </Box>
        </Tooltip>

        {/* Status Tool with Dropdown */}
        <Menu>
          <Tooltip label="Status" aria-label="Status tooltip">
            <MenuButton p={2} borderRadius="md" bg="#000" _hover={{ bg: "#333" }}>
              <Icon as={FaAdjust} color="white" />
            </MenuButton>
          </Tooltip>
          <MenuList>
            <MenuItem onClick={() => handleChangeStatus('active')} fontWeight={status === 'active' ? 'bold' : 'normal'}>Active</MenuItem>
            <MenuItem onClick={() => handleChangeStatus('closed')} fontWeight={status === 'closed' ? 'bold' : 'normal'}>Closed</MenuItem>
            <MenuItem onClick={() => handleChangeStatus('pending')} fontWeight={status === 'pending' ? 'bold' : 'normal'}>Pending</MenuItem>
          </MenuList>
        </Menu>

        {/* Options Tool with Dropdown */}
        <Menu>
          <Tooltip label="Options" aria-label="Options tooltip">
            <MenuButton p={2} borderRadius="md" bg="#000" _hover={{ bg: "#333" }}>
              <Icon as={FaEllipsisH} color="white" />
            </MenuButton>
          </Tooltip>
          <MenuList>
            <MenuItem onClick={onOpenDeleteModal}>Delete</MenuItem>
            <MenuItem>Forward</MenuItem>
         </MenuList>
        </Menu>
      </HStack>
    </Box>
  );
}


function ConfirmationModal({ isOpen, onClose, onConfirm }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Confirm Delete</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this conversation?
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button colorScheme="red" onClick={onConfirm}>
                        Delete
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
 

function StatusUpdateModal({ isOpen, onClose, onConfirm }) {
    const [selectedStatus, setSelectedStatus] = useState(null);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Select New Status</ModalHeader>
                <ModalBody>
                    <HStack m='0 auto'>
                        <Button 
                            colorScheme={selectedStatus === 'Active' ? 'blue' : 'gray'} 
                            onClick={() => setSelectedStatus('Active')}
                        >Active</Button>
                        <Button 
                            colorScheme={selectedStatus === 'Closed' ? 'blue' : 'gray'} 
                            onClick={() => setSelectedStatus('Closed')}
                        >Closed</Button>
                        <Button 
                            colorScheme={selectedStatus === 'Pending' ? 'blue' : 'gray'} 
                            onClick={() => setSelectedStatus('Pending')}
                        >Pending</Button>
                    </HStack>
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button 
                        colorScheme="blue" 
                        onClick={() => onConfirm(selectedStatus)}
                        isDisabled={!selectedStatus}
                    >
                        Send
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}


function SupportThread() {
    const { _id } = useParams(); // get conversation id from URL
    const [threads, setThreads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [customer_id, setCustomerID] = useState(true);
    
    const toast = useToast();
    const [subject, setSubject] = useState("");
    const history = useNavigate();
    const [editorContent, setEditorContent] = useState('');
    const [showEditor, setShowEditor] = useState(false); 

    const [statusModalOpen, setStatusModalOpen] = useState(false);

    const handleSend = (status) => {
        // You can now send the contents of the editor with this status to the backend
        console.log(editorContent, status, customer_id);

        axios.post(`https://locseat-backend-e47bf5c07959.herokuapp.com` + `/api/conversation-details/${_id}/reply`, {
            content: editorContent,
            status: status,
            customer_id: customer_id,
        })
        .then(response => {
            if (response.status === 201) {  // or another appropriate status code
                toast({
                    title: "Message Sent",
                    description: "Your message was sent successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                history('/support')
            }
        })
        .catch(error => {
            toast({
                title: "Error",
                description: "Failed to send the message.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            console.error("Failed to send message:", error);
        });


        // close the status modal
        setStatusModalOpen(false);
    };


    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleDelete = () => {
        axios.delete(`https://locseat-backend-e47bf5c07959.herokuapp.com` + `/api/conversation-details/${_id}`)
            .then(response => {
                if (response.status === 204) {
                    toast({
                        title: "Conversation Deleted",
                        description: "Conversation has been deleted successfully.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                    history('/support')
                }
            })
            .catch(error => {
                toast({
                    title: "Error",
                    description: "Failed to delete the conversation.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                console.error("Failed to delete:", error);
            });
    };

    useEffect(() => {
        axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com' + `/api/conversation-details/${_id}/threads`)
            .then(response => {
                setThreads(response.data._embedded.threads);
                console.log(response.data._embedded.threads);
                setCustomerID(response.data._embedded.threads[response.data._embedded.threads.length-1].customer.id)
                setLoading(false);
            })
            .catch(error => {
                console.error("Failed to fetch conversation threads:", error);
                setLoading(false);
            });
    }, [_id]);

    useEffect(() => {
        axios.get(`https://locseat-backend-e47bf5c07959.herokuapp.com/api/conversation-details/${_id}/subject`)
            .then(response => {
                setSubject(response.data.subject);  // adjust this according to the structure of your response
            })
            .catch(error => {
                console.error("Failed to fetch conversation subject:", error);
            });
    }, [_id]);
    
    const modules = {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],
          ['link', 'image', 'video'],
        ]        
      };

    return (
        <Flex w="100%" bg='#000'>
            <Sidebar />
            <Box p={4} w='full' pb={0}>
                <Box w='100%'>
                    <Flex w='80%' m='0 auto' mb={4} flexDirection="column">
                    <Toolbar status={threads[0]?.status} onOpenDeleteModal={onOpen}  onReplyClick={() => setShowEditor(!showEditor)}/>
                        <VStack spacing={5} alignItems="stretch" bg='#212121' borderRadius={12} p={4}>
                            
                            {loading ? (
                                <Text color="white">Loading...</Text>
                            ) : (
                                <>
                               <Heading mb={5} color="white">{subject}</Heading>
                               <ConfirmationModal isOpen={isOpen} onClose={onClose} onConfirm={handleDelete} />
                               <StatusUpdateModal isOpen={statusModalOpen} onClose={() => setStatusModalOpen(false)} onConfirm={handleSend} />
                               {showEditor && (
                                <>
                                    <ReactQuill 
                                        modules={modules}
                                        value={editorContent}   
                                        onChange={setEditorContent} 
                                        style={{color:'white'}}
                                    />
                                    <HStack mb={3} mt={0} justifyContent='right' w='100%'>
                                        <Button py={0} onClick={() => setShowEditor(false)}>Cancel</Button>
                                        <Button py={0} colorScheme="blue" onClick={() => setStatusModalOpen(true)}>Send</Button>
                                    </HStack>
                                </>
                            )}


                                {
                                threads.map((thread, index) => {
                                    // Check if the thread is of type 'lineitem'
                                    if (thread.type === 'lineitem') {
                                        // This is an action indicator
                                        return (
                                            <Box key={index} h="40px" mb={2} bg="#2b2b2b" p={2} my={-2} borderRadius="md">
                                                <Text fontSize="xs" color="gray.400">
                                                    {thread.action.text}
                                                </Text>
                                            </Box>
                                        );
                                    } else {
                                        // This is a regular message
                                        return (
                                            <ThreadItem key={index} thread={thread} isFirst={index === threads.length-1} />
                                        );
                                    }
                                })
                                }
                                </>
                            )}
                        </VStack>
                    </Flex>
                </Box>
            </Box>
        </Flex>
    );
}

function ThreadItem({ thread, isFirst}) {
    let responseLabel = "";
    // Check for the type of reply based on createdBy email.
    if (thread.createdBy.type === "user") { 
        responseLabel = "you replied";
    } else {
        if (isFirst) {
            responseLabel = "started the conversation";
        } else {
            responseLabel = "replied";
        }
    }

    return (
        <Box p={5} boxShadow="md" bg="#2b2b2b" borderRadius="md" color="white">
            <Flex justifyContent={'space-between'}>
                <Heading as="h5" size="sm" mb={3}>
                    {thread.createdBy.email} <Text as="span" fontSize="sm" color="gray.400">{responseLabel}</Text>
                </Heading>
                <Text fontSize="xs">{new Date(thread.createdAt).toLocaleString()}</Text>
            </Flex>
            
            <Text mt={3} px={6} dangerouslySetInnerHTML={{ __html: thread.body }} />
            {thread.type === "message" && (
                <Text mt={2} fontSize="xs" px={6} >Viewed at: {new Date(thread.openedAt).toLocaleString()}</Text>
            )}
        </Box>
    );
}


export default SupportThread;
