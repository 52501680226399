import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    Skeleton,
    Modal,
    ModalBody,
    ModalHeader,
    ModalContent, 
    ModalOverlay,
    ModalFooter,
    Input,
    ModalCloseButton,
    useToast,
} from '@chakra-ui/react';

import { AiFillPrinter } from "react-icons/ai";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import DataTable from '../components/NotificationDataTable';



export default function Notifications() {

    const user = useRecoilValue(userAtom);
    const mql = window.matchMedia('(max-width: 480px)');
    const handleOpen = () => setIsOpen(true);
    const toast = useToast();
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => setIsOpen(false);


    let mobileView = mql.matches;
    const [tableData1, setData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [titleInputValue, setTitleInputValue] = useState("");
    const handleTitleInputChange = (event) => setTitleInputValue(event.target.value);
    const [messageInputValue, setMessageeInputValue] = useState("");
    const handleMessageeInputChange = (event) => setMessageeInputValue(event.target.value);

  
    const getData = () => {
        const req = axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com' + "/api/notifications/all").then(res => {
          let notifications = res.data;    
          setData(notifications);
          setDataLoaded(true);
        }).catch(err => {
          console.log(err);
        });
    }

    const sendBlast = () => {
        const req = axios.post('http://127.0.01:8001' + '/api/notification/blast', 
        {
            title: titleInputValue,
            message: messageInputValue
        })
        toast({
            title: "Notification blast sent.",
            description: "The notification blast has been sent succesfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    }
    

    useEffect(() =>{
        getData();
      }, []) ;
    


    const columnsData1 = [
        {
          Header: "ID",
          accessor: "_id"
        },
        {
          Header: "DATE",
          accessor: "date"
        },
        {
          Header: "TITLE",
          accessor: "title"
        },
        {
          Header: "RECIPIENTS",
          accessor: "recipients"
        },

      ];
      


  return (
    <Flex w="100%" bg='#000'>
      <Sidebar />
      <Box p={4} w='full' pb={0}>

        <Box w='100%'>
            <Text textAlign={'center'} color='#fff' fontSize='2rem' fontWeight='800'>Blast Notifications</Text>
            <Text textAlign={'center'} color='gray.200' fontSize='1rem' fontWeight='500'>
                Send out blast notifications to all users.
            </Text>
            {user.role == 'Admin' && <Button bg="212121" color={'white'} variant={'outline'} onClick={handleOpen} mb={2}>New Notification</Button>}
            <Modal isOpen={isOpen} onClose={handleClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>New Notifications</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Text fontWeight={600} mb={2}>Create a new blast notification to send to all users.</Text>
                                <Text>Title</Text>
                                <Input value={titleInputValue} onChange={handleTitleInputChange} />
                                <Text>Message</Text>
                                <Input value={messageInputValue} onChange={handleMessageeInputChange} />
                            </ModalBody>

                            <ModalFooter>
                                <Button colorScheme="red" mr={3} onClick={sendBlast}>
                                    Send
                                </Button>
                                <Button variant="ghost" onClick={handleClose}>Cancel</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>


                <Box w='100%' >
                    <Flex>
                        <Flex direction='column' flex={1}>

                        <Skeleton isLoaded={dataLoaded}>
                            <Box>
                              <DataTable
                                  tableData={tableData1}
                                  columnsData={columnsData1}
                                  tableType='CantactList'
                                  hideButtons={true}
                                  userEmail={user.email}
                              />
                            </Box>
                        </Skeleton>
                        </Flex>

                    </Flex>
                </Box>
        </Box>
      </Box>
    </Flex>
  )
}