import { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    Skeleton,
    Grid,
    VStack,
} from '@chakra-ui/react';
import { FaStripe, FaUsers, FaGoogle } from "react-icons/fa";
import { AiFillPrinter } from "react-icons/ai";
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import { useLocation, Link } from 'react-router-dom';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';


export default function Home() {

    const [dataLoaded, setDataLoaded] = useState(false);

    const user = useRecoilValue(userAtom);
    const mql = window.matchMedia('(max-width: 480px)');

    let mobileView = mql.matches;
    // const [newCustomersData, setNewCustomersData] = useState({ '1DAY': 0, '7DAY': 0, '30DAY': 0 });

    useEffect(() => {
      //   axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com'+'/api/stripe/new_customers')
      //   .then(response => {
      //     const data = response.data;
      //     setNewCustomersData(data);
      // })
      // .catch(error => console.error('Error:', error));
    }, []);



    return (
      <Flex w="100%" bg='#000'>
          <Sidebar />
          <Box p={4} w='full' pb={0}>
              <Box w='100%'>
                  <Text textAlign={'center'} color='#fff' fontSize='2rem' fontWeight='800' mb={8}>Locseat Admin</Text>
          
  
                  <Box w='100%' px={6}>
                    <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                        <Link to={'/locticians'}>
                          <Box bg='#212121' p={4} borderRadius={8} _hover={{ boxShadow: '0px 4px 30px #883D1A' }}>
                            <VStack spacing={2} align="center" justify="center" color="#fff" fontSize="lg" >
                              <Text textAlign={'center'} mb={6}>Service Providers</Text>
                            </VStack>
                          </Box>
                        </Link>

                        <Link to={'/clients'}>
                          <Box bg='#212121' p={4} borderRadius={8}  height="fit-content" _hover={{ boxShadow: '0px 4px 20px #883D1A' }}>
                            <VStack spacing={2} align="center" justify="center" color="#fff" fontSize="lg" >
                              <Text textAlign={'center'} mb={6}>Customers</Text>
                            </VStack>
                          </Box>
                        </Link>

                        <Link to={'/bookings'}>
                          <Box bg='#212121' p={4} borderRadius={8}  height="fit-content" _hover={{ boxShadow: '0px 4px 20px #883D1A' }}>
                            <VStack spacing={2} align="center" justify="center" color="#fff" fontSize="lg" >
                              <Text textAlign={'center'} mb={6} whiteSpace="nowrap"  >Booking Management</Text>
                            </VStack>
                          </Box>
                        </Link>

                        <Link to={'/notifications'}>
                          <Box bg='#212121' p={4} borderRadius={8}  height="fit-content" _hover={{ boxShadow: '0px 4px 20px #883D1A' }}>
                            <VStack spacing={2} align="center" justify="center" color="#fff" fontSize="lg" >
                              <Text textAlign={'center'} mb={6} whiteSpace="nowrap"  >Notifications</Text>
                            </VStack>
                          </Box>
                        </Link>

                        <Link to={'/support'}>
                          <Box bg='#212121' p={4} borderRadius={8}  height="fit-content" _hover={{ boxShadow: '0px 4px 20px #883D1A' }}>
                            <VStack spacing={2} align="center" justify="center" color="#fff" fontSize="lg" >
                              <Text textAlign={'center'} mb={6} whiteSpace="nowrap"  >Support Tickets</Text>
                            </VStack>
                          </Box>
                        </Link>

                        <Link to={'/settings'}>
                          <Box bg='#212121' p={4} borderRadius={8}  height="fit-content" _hover={{ boxShadow: '0px 4px 20px #883D1A' }}>
                            <VStack spacing={2} align="center" justify="center" color="#fff" fontSize="lg" >
                              <Text textAlign={'center'} mb={6} whiteSpace="nowrap"  >Settings</Text>
                            </VStack>
                          </Box>
                        </Link>

                    </Grid>
                  </Box>
                  <Box width={'100%'} >
                    
                  </Box>
              </Box>
          </Box>
      </Flex>
  )
  
}