import { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    Skeleton,
    SimpleGrid,
    VStack,
    HStack,
    Divider,
    useToast,
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalCloseButton, 
    ModalBody, 
    ModalFooter,  

} from '@chakra-ui/react';
import { format } from 'date-fns';
import { AiFillPrinter } from "react-icons/ai";
import axios from 'axios';
import DataTable from '../components/UserDataTable2';
import DataTable2 from '../components/TransactionsDataTable';
import DataTable3 from '../components/ReviewsDataTable';
import { Avatar } from "@chakra-ui/react";
import { useLocation, useParams } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';

export default function User() {
    const [isOpen, setIsOpen] = useState(false);

    const onClose = () => setIsOpen(false);
    const onOpen = () => setIsOpen(true);
    const mql = window.matchMedia('(max-widt h: 480px)');
    const { _id } = useParams();
    const toast = useToast();
    const [user, setUser] = useState({});
    const [reviews, setReviews] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [userObj, _] = useRecoilState(userAtom)
    

  
  
    const getData = () => {
        const req = axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com' + `/api/user/data/${_id}`).then(res => {
            let app = res.data.appointments;
    
            app = app.map(appointment => {
                // check each field if it exists and convert true --> Y, false --> N
                if ("confirmed" in appointment) {
                    appointment.confirmed = appointment.confirmed ? "Y" : "N";
                }
                if ("canceled" in appointment) {
                    appointment.canceled = appointment.canceled ? "Y" : "N";
                }
                if ("reviewed" in appointment) {
                    appointment.reviewed = appointment.reviewed ? "Y" : "N";
                }
                return appointment;
            });

            setUser(res.data.user)
            setReviews(res.data.reviews)
            setAppointments(app)
            setTransactions(res.data.transactions)
            

            console.log(res.data.user);
            console.log(res.data.reviews);
            console.log(res.data.appointments);
            console.log(res.data.transactions);
    
          setDataLoaded(true);
        }).catch(err => {
          console.log(err);
        });
    }

    const banUser = () => {
        axios.post('https://locseat-backend-e47bf5c07959.herokuapp.com/api/user/ban',  {_id: _id})
            .then(res => {
                toast({
                    title: "Action Complete.",
                    description: `User ${_id} ban status has been updated.`,
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
                getData();
            })
            .catch(err => {
                console.log(err);
                toast({
                    title: "An error occurred.",
                    description: "Unable to update user ban status.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            });
    };
    

    useEffect(() =>{
        getData();
      }, []) ;
    
      const columnsData1 = [
        {
          Header: "ID",
          accessor: "_id"
        },
        {
          Header: "Date Created",
          accessor: "dateCreated"
        },
        {
          Header: "Appointment Date",
          accessor: "appointmentDate"
        },
        {
            Header: "Loctician",
            accessor: "locticianID"
        },
        {
          Header: "Conmfirmed",
          accessor: "confirmed"
        },
        {
            Header: "Canceled",
            accessor: "canceled"
        },
        {
            Header: "Reviewed",
            accessor: "reviewed"
        },
      
      ];

      const columnsData2 = [
        {
          Header: "ID",
          accessor: "_id"
        },
        {
            Header: "Total",
            accessor: "totalCost"
          },
        {
          Header: "Date Created",
          accessor: "dateCreated"
        },
        {
          Header: "Appointment Date",
          accessor: "appointmentDate"
        },
        {
            Header: "Loctician",
            accessor: "locticianID"
        },
        {
            Header: "Payment ID",
            accessor: "paymentIntentID"
        },
      
      ];

      const columnsData3 = [
     
        {
            Header: "Date",
            accessor: "date"
          },
        {
          Header: "Rating",
          accessor: "rating"
        },
        {
          Header: "Review",
          accessor: "review"
        },
        {
            Header: "Loctician",
            accessor: "locticianID"
        },
  
      
      ];

      


      return (
        <Flex w="100%" bg="#000">
            <Sidebar />
            <Box p={4} w='full' pb={0}>
                <Box w='100%' px={5} mb={12}>
                <Text textAlign={'left'} color='#fff' fontSize='2rem' fontWeight='800'>
                    User ID: {_id} {user?.banned == 'banned' && '(Banned)'}
                    </Text>
                    {user?.banned == 'banned' 
                       ?
                       <>
                       {userObj.role == 'Admin' && <Button colorScheme="red" onClick={onOpen}>Unban User</Button>}
                       </>
                       :
                       <>
                       {userObj.role == 'Admin' && <Button colorScheme="red" onClick={onOpen}>Ban User</Button>}
                       </>
                    }
                    <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Confirm Ban</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                Are you sure you want to {user?.banned == 'banned'? 'unban' : 'ban'} this user?
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="red" mr={3} onClick={banUser}>
                                    Yes
                                </Button>
                                <Button variant="ghost" onClick={onClose}>No, Cancel</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    <Skeleton isLoaded={dataLoaded}>
                        <SimpleGrid columns={{base: 1, md: 1}} spacing={4} mt={4}>

                            <VStack align="start" spacing={2}  bg='#212121' p={4} borderRadius={8}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff'>User Details</Text>
                                <HStack spacing="24px">
                                    <Avatar 
                                        src={user?.profilePic}
                                        name={`${user?.fullName}`}
                                        fallbackSrc="https://via.placeholder.com/150"
                                    />
                                    <VStack align="start">
                                        <Text color='#fff'> <b>Name:</b> {`${user?.fullName}`}</Text>
                                        <Text color='#fff'> <b>Email:</b> {user.email}</Text>
                                        <Text color='#fff'> <b>Phone:</b> {user.phone}</Text>
                                        <Text color='#fff'> <b>Stripe Customer ID:</b> {user.stripeCustID}</Text>
                                    </VStack>
                                </HStack>
                            </VStack>
                            <Text color='#fff'> <b>Appointments</b></Text>
                            {appointments.length > 0 ? (
                                <DataTable
                                    tableData={appointments}
                                    columnsData={columnsData1}
                                    tableType='CantactList'
                                    hideButtons={true}
                                    userEmail={user.email}
                                />
                            ) : (
                                <Text color='#fff'> No Appointments</Text>
                            )}

                            <Text color='#fff'> <b>Transactions</b></Text>
                            {transactions.length > 0 ? (
                                <DataTable2
                                    tableData={transactions}
                                    columnsData={columnsData2}
                                    tableType='CantactList'
                                    hideButtons={true}
                                    userEmail={user.email}
                                />
                            ) : (
                                <Text color='#fff'> No Transactions</Text>
                            )}

                            <Text color='#fff'> <b>Reviews</b></Text>
                            {reviews.length > 0 ? (
                                <DataTable3
                                    tableData={reviews}
                                    columnsData={columnsData3}
                                    tableType='CantactList'
                                    hideButtons={true}
                                    userEmail={user.email}
                                />
                            ) : (
                                <Text color='#fff'> No Reviews</Text>
                            )}

                        </SimpleGrid>
                    </Skeleton>
                </Box>
            </Box>
        </Flex>
    )
}