import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home"
import Bookings from "./pages/Bookings"
import Locticians from "./pages/Locticians"
import Booking from "./pages/Booking";
import Transactions from "./pages/Transactions";
import User from "./pages/User";
import Loctician from "./pages/Loctician";
import Transaction from "./pages/Transaction";
import Users from './pages/Users';
import Notifications from './pages/Notifications';
import Notification from './pages/Notification';
import Review from "./pages/Review";
import Settings from './pages/Settings';
import Admin from './pages/Admin';
import Support from './pages/Support';
import SupportThread from './pages/SupportThread';
import Subscriptions from './pages/Subscriptions';
import Subscription from './pages/Subscription';

import './index.css'
import { userAtom } from './recoil/userAtoms'; // Import your sidebarState
import { useRecoilValue } from 'recoil'; // Import useRecoilState

function App() {
  const user = useRecoilValue(userAtom);
  

  return (
    <Router>
      <div className="App">
      <Routes>
        <Route path='/' element={<Login/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/home' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Home/>}/>
        <Route path='/support' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Support/>}/>
        <Route path='/conversation/:_id' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <SupportThread/>}/>
        <Route path='/bookings' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Bookings/>}/>
        <Route path='/subscriptions' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Subscriptions/>}/>
        <Route path='/subscription/:_id' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Subscription/>}/>
        <Route path='/settings' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Settings/>}/>
        <Route path='/notifications' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Notifications/>}/>
        <Route path='/booking/:_id' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Booking/>}/>
        <Route path='/user/:_id' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <User/>}/>
        <Route path='/admin/:_id' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Admin/>}/>
        <Route path='/notification/:_id' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Notification/>}/>
        <Route path='/loctician/:_id' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Loctician/>}/>
        <Route path='/transaction/:_id' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Transaction/>}/>
        <Route path='/review/:_id' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Review/>}/>
        <Route path='/locticians' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Locticians/>}/>
        <Route path='/clients' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Users/>}/>
        <Route path='/transactions' element={Object.keys(user).length === 0 ? <Navigate to="/login" /> : <Transactions/>}/>
        <Route path='*' element={<Login/>}/>
      </Routes>
      </div>
    </Router>
  );
}

export default App;