import React from 'react'
import {
    Flex,
    Text,
    Icon,
    Link,
    Menu,
    MenuButton,
} from '@chakra-ui/react'

export default function NavItem({ icon, title, description, active, navSize }) {
    return (
        <Flex
            mt={4}
            flexDir="column"
            w="100%"
            alignItems={navSize == "small" ? "center" : "flex-start"}
            borderBottom={'1px solid gray'}
        >
            <Menu placement="right">
                <Link
                    backgroundColor={active && "#AEC8CA"}
                    color='#fff'
                    _hover={{ textDecor: 'none', backgroundColor: "#EFEFEF",textColor:'#000' }}
                    w='100%'
                >
                    <MenuButton w="100%" >
                        <Flex>
                            {title == 'Booking Management' ? 
                            <>
                            <Text ml={2} fontSize={"0.8rem"} display={navSize == "small" ? "none" : "flex"}>Booking</Text>
                            <Text ml={2} fontSize={"0.8rem"} display={navSize == "small" ? "none" : "flex"}>Management</Text>
                            </>
                            :
                            <Text ml={2} fontSize={"0.8rem"} display={navSize == "small" ? "none" : "flex"}>{title}</Text>
                            }
                        </Flex>
                    </MenuButton>
                </Link>
              
            </Menu>
        </Flex>
    )
}