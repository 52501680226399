import { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    Skeleton,
    SimpleGrid,
    VStack,
    HStack,
    Divider,
    useToast,
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalCloseButton, 
    ModalBody, 
    ModalFooter,  

} from '@chakra-ui/react';
import { format } from 'date-fns';
import { AiFillPrinter } from "react-icons/ai";
import axios from 'axios';
import DataTable from '../components/UserDataTable2';
import DataTable2 from '../components/TransactionsDataTable';
import DataTable3 from '../components/ReviewsDataTable';
import { Avatar } from "@chakra-ui/react";
import { useLocation, useParams } from 'react-router-dom';


export default function Notification() {
    const [isOpen, setIsOpen] = useState(false);

    const onClose = () => setIsOpen(false);
    const onOpen = () => setIsOpen(true);
    const mql = window.matchMedia('(max-widt h: 480px)');
    const { _id } = useParams();
    const toast = useToast();
    const [notif, setNotif] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    

  
  
    const getData = () => {
        const req = axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com' + `/api/notification/${_id}`).then(res => {
          setNotif(res.data.notif)
          setDataLoaded(true);
        }).catch(err => {
          console.log(err);
        });
    }

    

    useEffect(() =>{
        getData();
      }, []) ;
    
     

      


      return (
        <Flex w="100%" bg="#000">
            <Sidebar />
            <Box p={4} w='full' pb={0}>
                <Box w='100%' px={5} mb={12}>
                <Text textAlign={'left'} color='#fff' fontSize='2rem' fontWeight='800'>
                    Notification ID: {_id}
                    </Text>
               
                    <Skeleton isLoaded={dataLoaded}>
                        <SimpleGrid columns={{base: 1, md: 1}} spacing={4} mt={4}>

                            <VStack align="start" spacing={2}  bg='#212121' p={4} borderRadius={8}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff'>Notification Details</Text>
                                <HStack spacing="24px">
                                    <VStack align="start">
                                        <Text color='#fff'> <b>Date:</b> {notif.date} </Text>
                                        <Text color='#fff'> <b>Title:</b> {notif.title} </Text>
                                        <Text color='#fff'> <b>Message:</b> {notif.message} </Text>
                                        <Text color='#fff'> <b>Recipients:</b> {notif.recipients} </Text>
                                    </VStack>
                                </HStack>
                            </VStack>
                          

                         

                        </SimpleGrid>
                    </Skeleton>
                </Box>
            </Box>
        </Flex>
    )
}