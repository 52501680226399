import React, { useState, useEffect } from 'react'
import { 
    Flex, 
    Text, 
    Divider, 
    VStack,
    Collapse,
    Box,
    Avatar, 
    Button,
    Heading, 
    Image,
    IconButton 
} from '@chakra-ui/react'
import {
    FiHome, 
    FiMessageSquare, 
    FiUsers 
} from 'react-icons/fi'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom';
import { ImPhone, ImQrcode } from "react-icons/im";
import NavItem from './NavItem'
import { GoZap } from "react-icons/go";
import { FaRobot } from "react-icons/fa";
import { userAtom, sidebarState } from '../recoil/userAtoms'; // Import your sidebarState
import { useRecoilValue, useRecoilState , useSetRecoilState} from 'recoil'; // Import useRecoilState
import { BsPhoneVibrate } from "react-icons/bs";

export default function Sidebar() {
    const [navSize, changeNavSize] = useState("large")
      const user = useRecoilValue(userAtom);
      const [isUserManagementExpanded, setUserManagementExpanded] = useState(false);
      const [isPaymentsBillingExpanded, setPaymentsBillingExpanded] = useState(false);


      
      const [isSidebarCollapsed, setSidebarState] = useRecoilState(sidebarState); // Use your sidebarState

      const setUser = useSetRecoilState(userAtom);

        const logout = () => {
            setUser({});
        }
      

    return (
        <Flex
            pos='static'
            left="0"
            h='inherit'
            minHeight={'100vh'}
            boxShadow="0 4px 12px 4px rgba(0, 0, 0, 0.15)"
            bg="#212121"
            borderRadius={isSidebarCollapsed ? "0px 0px 0px 15px" : "0px 0px 30px 0px"}
            w={isSidebarCollapsed ? "0px" : "20%"}
            flexDir="column"
        >
            <IconButton 
                icon={isSidebarCollapsed ? <IoIosArrowForward /> : <IoIosArrowBack />} 
                onClick={() => setSidebarState(!isSidebarCollapsed)} 
                aria-label="Collapse/Expand sidebar"
                alignSelf='flex-end'
                cursor={'pointer'}
                mr={isSidebarCollapsed ? -8 : -4}
                bg={'#a3a3a3'}
                top={'40%'}
            />
            {!isSidebarCollapsed &&
            <>
            <Flex
                py="5%"
                flexDir="column"
                w="100%"
                alignItems={navSize == "small" ? "center" : "flex-start"}
                as="nav"
            >

                
                <Link style={{width:'100%', padding:4}} to={'/home'}>
                    <Image src='../logo-no-background.png'/>
                </Link>

                <Link style={{width:'100%'}} to={'/home'}>
                    <NavItem navSize={navSize} title="Dashboard"/>
                </Link>
                <Box style={{width:'100%'}} onClick={() => setUserManagementExpanded(!isUserManagementExpanded)}>
                    <NavItem navSize={navSize} icon={GoZap} title="User Management" />

                    <Box key={'col1'}>
                        {/* <Button onClick={() => handleToggle(service._id)} variant="outline" size="sm" bg={'#303030'}>
                        </Button> */}
                        <Collapse in={isUserManagementExpanded}>
                            <Link style={{width:'100%'}} to={'/locticians'}>
                                <NavItem navSize={navSize} icon={GoZap} title="• Service Providers" />
                            </Link>
                            <Link style={{width:'100%'}} to={'/clients'}>
                                <NavItem navSize={navSize} icon={GoZap} title="• Customers" />
                            </Link>

                        </Collapse>
                    </Box>
                </Box>
                <Link style={{width:'100%'}} to={'/bookings'}>
                    <NavItem navSize={navSize} title="Booking Management"/>
                </Link>
                {/* <Box style={{width:'100%'}} onClick={() => setPaymentsBillingExpanded(!isPaymentsBillingExpanded)}>
                    <NavItem navSize={navSize} icon={GoZap} title="Payments & Billing" />

                    <Collapse in={isPaymentsBillingExpanded}>
                        <Link style={{width:'100%'}} to={'/transactions'}>
                            <NavItem navSize={navSize} icon={GoZap} title="• Transactions" />
                        </Link>
                        <Link style={{width:'100%'}} to={'/subscriptions'}>
                            <NavItem navSize={navSize} icon={GoZap} title="• Subscriptions" />
                        </Link>
                    </Collapse>
                </Box> */}
                
                {/* <Link style={{width:'100%'}} to={'/bookings'}>
                    <NavItem navSize={navSize} title="Marketing & Referrals"/>
                </Link> */}
                <Link style={{width:'100%'}} to={'/notifications'}>
                    <NavItem navSize={navSize} title="Notifications"/>
                </Link>
                <Link style={{width:'100%'}} to={'/support'}>
                    <NavItem navSize={navSize} title="Support Tickets"/>
                </Link>
                <Link style={{width:'100%'}} to={'/settings'}>
                    <NavItem navSize={navSize} title="Settings"/>
                </Link>
                
                {/* <Link style={{width:'100%'}} to='/transactions'>
                    <NavItem navSize={navSize} icon={FiUsers} title="Transactions" />
                </Link> */}
                
        
            
           
            </Flex>

            <Flex
                p="5%"
                flexDir="column"
                w="100%"
                alignItems={navSize == "small" ? "center" : "flex-start"}
                mb={4}
            >
                <Flex mt={4} align="center">
                    <Avatar size="sm" src="avatar-1.jpg" />
                    <Flex flexDir="column" ml={4} display={navSize == "small" ? "none" : "flex"}>
                        <Heading as="h3" color='#fff'  size="sm">Locseat</Heading>
                        <Text color='#fff' >Admin</Text>
                    </Flex>
                    
                </Flex>
                <Button onClick={logout} bg="#a3a3a3" w="100%" mt={12}>
                    Logout
                </Button>
            </Flex>
            </>
            }
        </Flex>
    )
}