import { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar'
import { 
    Box, 
    Flex,
    Text,
    Skeleton,
    SimpleGrid,
    VStack,
    HStack,
    Divider,
    Collapse, Button, useToast,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import axios from 'axios';
import { Avatar } from "@chakra-ui/react";
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';
 

export default function Subscription() {
    const mql = window.matchMedia('(max-widt h: 480px)');
    const { _id } = useParams();
    const [isOpen, setIsOpen] = useState(false);

    const onClose = () => setIsOpen(false);
    const onOpen = () => setIsOpen(true);
    const [user, setUser] = useState({});
    const [loctician, setLoctician] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const toast = useToast();
    const history = useNavigate();

    const [userObj, _] = useRecoilState(userAtom)
    const [stripeCustomerDetails, setStripeCustomerDetails] = useState({});
    const [stripeSubscriptionDetails, setStripeSubscriptionDetails] = useState([]);

    

    // DEPRICATED
    // const getStripeData = async () => {
    //     try {
    //         let customerDetails = await axios.get(`https://locseat-backend-e47bf5c07959.herokuapp.com` + `/api/get-stripe-customer-details/${_id}`);
    //         let subscriptionDetails = await axios.get(`https://locseat-backend-e47bf5c07959.herokuapp.com` + `/api/get-subscription-details/${_id}`);
            
    //         setStripeCustomerDetails(customerDetails.data);
    //         setStripeSubscriptionDetails(subscriptionDetails.data);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }
    
  
    const getData = () => {
        const req = axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com' + `/api/loctician/data/${_id}`).then(res => {
            let app = res.data.appointments;
    
            app = app.map(appointment => {
                // check each field if it exists and convert true --> Y, false --> N
                if ("confirmed" in appointment) {
                    appointment.confirmed = appointment.confirmed ? "Y" : "N";
                }
                if ("canceled" in appointment) {
                    appointment.canceled = appointment.canceled ? "Y" : "N";
                }
                if ("reviewed" in appointment) {
                    appointment.reviewed = appointment.reviewed ? "Y" : "N";
                }
                return appointment;
            });

            setUser(res.data.user)
            setLoctician(res.data.loctician)
    
          setDataLoaded(true);
        }).catch(err => {
          console.log(err);
        });
    }
    


    useEffect(() => {
        getData();
        // getStripeData();
    }, []);
    
    
    return (
        <Flex w="100%" bg="#000">
            <Sidebar />
            <Box p={4} w='full' pb={0}>
                <Box w='100%' px={5} mb={12}>
                    <Text textAlign={'left'} color='#fff' fontSize='2rem' fontWeight='800'>
                    Loctician ID: {_id} {user?.banned == 'banned' && '(Banned)'}
                    </Text>
                    {/* <Text textAlign={'left'} color='#fff' fontSize='2rem' fontWeight='800'>
                    Subscription: <span style={{color: stripeSubscriptionDetails[stripeSubscriptionDetails.length-1]?.status== 'active'? 'green' : 'red'}}>{stripeSubscriptionDetails[stripeSubscriptionDetails.length-1]?.status.toUpperCase()}</span>
                    </Text> */}
    
                    <Skeleton isLoaded={dataLoaded}>
                        <SimpleGrid columns={{base: 1, md: 1}} spacing={4} mt={4}>
    
                        <VStack align="start" spacing={2} bg='#212121' p={4} borderRadius={8}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff'>Loctician Details</Text>
                                <HStack spacing="24px">
                                    <Avatar 
                                        src={loctician?.coverPhoto}
                                        name={`${user?.fullName}`}
                                        size={'lg'}
                                        fallbackSrc="https://via.placeholder.com/150"
                                    />
                                    <VStack align="start">
                                        <Text color='#fff'> <b>Name:</b> {`${user?.fullName}`}</Text>
                                        <Text color='#fff'> <b>Email:</b> {user.email}</Text>
                                        {/* <Text color='#fff'> <b>Stripe Account ID:</b> {loctician.stripeAcctID}</Text> */}
                                        {/* <Text color='#fff'> <b>Stripe Customer ID:</b> {user.stripeCustID}</Text> */}
                                        <Button onClick={() => history(`/loctician/${_id}`)}>More Details</Button>
                                    </VStack>
                                </HStack>
                            </VStack>
    
                            {/* <VStack align="start" spacing={2} bg='#212121' p={4} borderRadius={8}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff' textDecoration={'underline'}>Stripe Customer Details</Text>
                                <Text color='#fff'> 
                                    <b>Created:</b>&nbsp;
                                    {stripeCustomerDetails.customer_created_date ? format(new Date(stripeCustomerDetails.customer_created_date * 1000), 'MM/dd/yyyy') : 'N/A'}
                                </Text>
                                <Text color='#fff'> <b>Total Spent:</b> {stripeCustomerDetails.total_spent}</Text>
                            </VStack> */}
    
                            {/* <VStack align="start" spacing={2} bg='#212121' p={4} borderRadius={8} mt={4}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff' textDecoration={'underline'}>Invoices</Text>
                                {stripeCustomerDetails.invoices?.map(invoice => (
                                    <HStack key={invoice.invoice_id} spacing="24px" w="100%" bg='#a3a3a3' px={4} borderRadius={6}>
                                        <Text color='#000' flex="1" style={{ whiteSpace: 'nowrap' }}> <b>ID:</b> {invoice.invoice_id}</Text>
                                        <Text color='#000' flex="1"> <b>Amount:</b> ${invoice.amount.toFixed(2)}</Text>
                                        <Text color='#000' flex="1"> <b>Status:</b> 
                                            <span style={{fontWeight:'bold', color: invoice?.status== 'paid'? 'green' : 'red'}}>&nbsp;{invoice?.status.toUpperCase()}</span>
                                        </Text>
                                        <Text color='#000' flex="1"> 
                                            <b>Date:</b> &nbsp;
                                            {invoice.invoice_date ? format(new Date(invoice.invoice_date * 1000), 'MM/dd/yyyy') : 'N/A'}
                                        </Text>
                                    </HStack>
                                ))}
                            </VStack> */}
    
                            {/* <VStack align="start" spacing={2} bg='#212121' p={4} borderRadius={8} mt={4}>
                                <Text fontSize="xl" fontWeight="semibold" color='#fff' textDecoration={'underline'}>Subscriptions</Text>
                                {stripeSubscriptionDetails?.map(subscription => (
                                    <VStack key={subscription.id} align="start" spacing={2}>
                                        <Text color='#fff'> 
                                            <b>Start Date:</b> &nbsp;
                                            {subscription.current_period_start ? format(new Date(subscription.current_period_start * 1000), 'MM/dd/yyyy') : 'N/A'}
                                        </Text>
                                        <Text color='#fff'> 
                                            <b>Next Payment Date:</b> &nbsp;
                                            {subscription.current_period_end ? format(new Date(subscription.current_period_end * 1000), 'MM/dd/yyyy') : 'N/A'}
                                        </Text>
                                        <Text color='#fff'> <b>Status:</b> {subscription?.status}</Text>
                                        {subscription.canceled_at && <Text color='red' fontWeight={'bold'}> 
                                            <b>Canceled:</b> &nbsp;
                                            {format(new Date(subscription.canceled_at * 1000), 'MM/dd/yyyy')}
                                        </Text>}
                                    </VStack>
                                ))}
                            </VStack> */}
                        </SimpleGrid>
                    </Skeleton>
                </Box>
            </Box>
        </Flex>
    )
    
}